import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Shop from '../components/shop'
const ShopPage = () => (
  <Layout>
    <SEO title="Shop" />
<Shop/>

  </Layout>
)

export default ShopPage