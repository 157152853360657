import React from 'react'
import styled from 'styled-components'
import slide1 from '../../images/shop/dsImg2.png'
import slide2 from '../../images/shop/WFH.png'
export default function ShopImage() {
    return (
        <ShopImageWrap className="container-fluid  p-0">

           <div className="row no-gutter" style={{backgroundColor:"#111111"}}>

               <div className={`col-sm-12  content`}>

               <div>
                <h2>HEIGHT ADJUSTABLE DESKS</h2>
                <p>by <span>Design Series</span> powered by <span>Actiforce</span></p>
                </div>

                </div>


               <div className="col-sm-12">

               <img src={slide1} alt="imge" width="100%"/>
               
               </div>

           </div>

           <div className="row">

               <div className="col-sm-12" style={{position:"relative"}} >

               <img src={slide2} alt="imge2" width="100%"/>
                 
                 {/* <div className="content2">
                     <h2>
                         WORK FROM<br/> <span>HOME</span><br/> CONCEPT
                     </h2>
                 </div> */}

               </div>
           </div>

        </ShopImageWrap>
    )
}

const ShopImageWrap = styled.div`

background-color:#111111;
overflow:hidden;

img{
    height:100%;
    

}

.content{

    display:flex;
    align-items:center;
    justify-content:center;
    padding:3rem 0;

    h2{

        color:grey;
        font-size:2.5rem;
        line-height:1.6;
        letter-spacing:0.03rem;
        font-weight:bold;
        font-family: 'Montserrat', sans-serif;

        text-align: center;
     
    }

    p{

color:white ;
text-align: center;
font-size: 1.3rem;  
      color:grey;
      font-weight:bold;
      font-family: 'Montserrat', sans-serif;



span{

 color:white;
}

}
}


.content2{

    position:absolute;
    top:20%;
    left:70%;
    transform:translate(-50%, -50%);
    h2{

        color:grey;
        font-size:3.7rem;
        line-height:1.3;
        letter-spacing:0.05rem;
        font-weight:bold;
        font-family:'Poppins', sans-serif;
        text-align: left;

        span{

            color:white;
        }
    }


    
}


@media(max-width:960px){
transform:translateY(-50px);
.content h2{
  
    font-size:2rem;
    padding:1rem 0.5rem;


}
 
    /* .content2{

position:absolute;
top:30%;
left:70%;
transform:translate(-50%, -50%);
h2{

    color:grey;
    font-size:1.7rem;
    line-height:1.3;
    letter-spacing:0.05rem;
    font-weight:bold;
    font-family:'Poppins', sans-serif;
    text-align: left;

    span{

        color:white;
    }
}



} */
    

}
`
