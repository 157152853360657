import React from 'react'
import styled from 'styled-components'
import { GiBrain, GiStrongMan, GiBackPain, GiHeartBeats, GiBodyBalance } from 'react-icons/gi';
import imge from '../../images/shop/sideimg.jpg'
import imge2 from '../../images/shop/newImages/new-2.jpg'
import mg2 from '../../images/shop/newImages/new-1.jpg'
import bimg1 from '../../images/shop/newImages/new-3.jpg'
import bimg2 from '../../images/shop/newImages/new-4.jpg'
import bimg3 from '../../images/shop/newImages/new-5.png'

import mb1 from '../../images/shop/mb (1).png'

import mb2 from '../../images/shop/mb (2).png'


export default function Benifits() {



const HeadindData = [{

    title:`Muscle Problem
    `,
    text:`If you stay seated all day, your leg muscles and gluteus muscles will become inactive, Thus reducing your ability to sit up straight.`,
    icon:<GiStrongMan/>
},
{

    title:`Brain function slowing down
    `,
    text:`If you stay fixed in one position, insufficient fresh oxygen-rich blood will be pumped around the body, causing brain function to slow down`,
    icon:<GiBrain/>
},
{

    title:`Back Problems
    `,
    text:`Staying seated for long periods causes weight pressure to be unevenly distributed over the spine, leading to spinal problems in the lower back.`,
    icon:<GiBackPain/>
},
{

    title:`Poor Circulation
    `,
    text:`Remaining seated for too long may result in swollen ankles and varicose veins, caused by fluids sinking down into the legs`,
    icon:<GiBodyBalance/>
},
{

    title:`Heart Risks
    `,
    text:`Since muscles are less active while a person is seated, they burn less fat. Together with a higher blood pressure and elevated cholesterol levels, this increases the risk of heart disease.`,
    icon:<GiHeartBeats/>
}]


    return (
        <BenifitsWrap className="container-fluid " style={{backgroundColor:"#111111"}} >

            <div className="row">
                <div className="col-sm-12 col-md-7 p-sm-5">

              <div className="content mt-sm-5">
                  <h2>HEALTH<br/><span>BENEFITS</span></h2>
                  <h4>By regularly varying <br/>your posture between<br/> sitting and standing<br/> positions you will avoid:</h4>
              </div>

              <div className="heading">
                      <div className="row mt-sm-5 ml-sm-5" style={{borderLeft:"2px solid grey"}}>
                

                    {
                        HeadindData.map((item, i)=>{

                        return (<React.Fragment   key={i}>
                        
                        <div className="col-sm-3 col-md-2 my-4"  >

                           <div className="icon">{item.icon}</div>
                       

                             </div>

                             <div className="col-sm-7 col-md-4 my-4" >
                                <div className="headcontent text-secondary">
                                    <h3 className="mb-3">{item.title}</h3>
                                    <p>{item.text}</p>
                                </div>

                                </div>
                                
                                </React.Fragment>)
                            
                             
                         
                        })
                    }
                     </div> 
                

              </div>


                </div>
                <div className="col-sm-12 col-md-5">

                    <img src={imge} width="100%" alt="simge"/>
                </div>

            </div>

                    <img className="vimg my-4" src={imge2} alt="jj" width="100%"/>
                        <img className="mimg my-4" src={mg2} alt="jj" width="100%"/>

                        <img className="vimg my-4" src={bimg1} alt="jj" width="100%"/>
                        <img className="mimg my-4" src={mb1} alt="jj" width="100%"/>


                        <img  src={bimg2} alt="jj" width="100%"/>

                        <img className="vimg my-4" src={bimg3} alt="jj" width="100%"/>
                        <img className="mimg my-4" src={mb2} alt="jj" width="100%"/>


        </BenifitsWrap>
    )
}

const BenifitsWrap = styled.div`
background-color:#010101;


.content{



    h2{

      
        color:grey;
            font-size:3.9rem;
            transform:translateX(8rem);
            font-family: 'Montserrat', sans-serif;

        span{

        font-size:4.5rem;
        color:white;
        line-height:1.6;
        
        }
    }

    h4{
       padding:1rem;
        color:grey;
        font-size:2rem;
        line-height:1.4;
        font-weight:200;
        letter-spacing:0.05rem;
        transform:translateX(7rem);
        font-family: 'Montserrat', sans-serif;


    }
}

.icon{

display:flex;
align-items:center;
justify-content:center;
    svg{

        font-size:4rem;
        color:white;
    }
}

.headcontent{
    
    h3{

        color:orange;
        text-align:left;
          font-family: 'Montserrat', sans-serif;


        
    }

    p{

        color:grey;
        font-size:1.3rem;
        text-align:left;
          font-family: 'Montserrat', sans-serif;


    }
}

.vimg{

    display:block;
}

.mimg{

display:none;
}
@media(max-width:960px){

    .vimg{

display:none;
}

.mimg{

display:block;
}

    .headcontent{
    
    h3{

        color:orange;
        text-align:center

        
    }

    p{

        color:grey;
        font-size:1.3rem;
text-align:center
    }
}






    .content{



h2{

    padding:2rem;
    color:grey;
        font-size:2.9rem;
        transform:translateX(-1px);
    span{

    font-size:3.4rem;
    color:white;
    line-height:1.6;
    
    }
}

h4{
   padding:0rem 2rem;
    color:grey;
    font-size:2rem;
    line-height:1.6;
    font-weight:200;
    letter-spacing:0.05rem;
    transform:translateX(0px)
}
}

}
`