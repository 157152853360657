import React from 'react'
import styled from 'styled-components'
import ShopImage from './shopImage'
import Benifits from './benifits'
import {Link} from 'gatsby'
import forest from '../../images/shop/forest.jpg'
export default function Product() {




    return (
        <ShopWrap className="container-fluid p-0" style={{backgroundColor:"#111111"}}>

         <ShopImage/>
        <Benifits/>

<div className="container py-sm-5">

<div className="row no-gutter">
    <div className="col-sm-7">

        <img src={forest} alt="forest" style={{marginRight:"auto"}} width="100%"/>
    </div>

    <div className="col-sm-5 btnp">

<div className="btnContianer py-5 py-sm-0">

        <h2 className="text-light">Tailor-made<br/> 
for all sorts of<br/> 
Business</h2>

         <Link to="/product" className="viewall">VIEW ALL MODELS</Link>

</div>

    </div>

</div>

       
           
</div>
        </ShopWrap>
    )
}

const ShopWrap = styled.div`


.btnp{

display:flex;
align-items:center;
justify-content:center;

h2{

    text-align:left;
    line-height:1.5;
    font-size:3rem;
    font-weight:200;
    font-family: 'Montserrat', sans-serif;
}



.viewall{

padding:1rem;

display:block;

background-color:#23B2B7;
border:none;
border-radius:0.5rem;
color:white;
font-size:1.3rem;
text-decoration:none;
text-align:center;

}
}



@media(max-width:960px){
.btnp{

  h2{

text-align:center;
line-height:1.2;
font-size:2rem;
font-weight:200;
font-family: 'Montserrat', sans-serif;
}
    .viewall{
width:100%;

    }

}
  
 
    
}

`